import { UseFormReturn } from "react-hook-form";
import { CustomerAndPartnerSelectionOption } from "src/components/CustomerAndPartnerSelection/hooks/useCustomerAndPartnerSelectionHandler";
import { getPOSBuyerDetailAlert } from "src/services/POS.service";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { PosCartSchemaType } from "../posCartSchema";

interface UseCartSectionHandlerProps {
  isEditOrder: boolean;
  transactionId: number | undefined;
  form: UseFormReturn<PosCartSchemaType, any, undefined>;
  handleShowRepeatTransactionModal: () => void;
  handleGoToPaymentSummary: () => void;
}

interface HandleNextProps {
  isEditOrder: boolean;
  transactionId: number | undefined;
  form: UseFormReturn<PosCartSchemaType, any, undefined>;
  handleShowAlert: () => void;
  handleGoToPaymentSummary: () => void;
}

const handleNext = async ({
  isEditOrder,
  transactionId,
  form,
  handleShowAlert,
  handleGoToPaymentSummary,
}: HandleNextProps) => {
  const values = form.getValues();

  const params = {
    id: parseInt(`${values.customer.id}`, 0),
    type: values.customer.type,
    edit: isEditOrder ? 1 : 0,
    transactionId,
  };

  if (params.id) {
    try {
      const { data } = await getPOSBuyerDetailAlert(params);

      const isShowAlert = data.alert;

      if (isShowAlert) {
        handleShowAlert();
      } else {
        handleGoToPaymentSummary();
      }
    } catch (error) {
      apiErrorHandler({ error, form });
    }
  } else {
    handleGoToPaymentSummary();
  }
};

const useCartSectionHandler = ({
  isEditOrder,
  transactionId,
  form,
  handleShowRepeatTransactionModal,
  handleGoToPaymentSummary,
}: UseCartSectionHandlerProps) => {
  const formValues = form.getValues();

  const handleAddExistingCustomerInfo = (
    value: CustomerAndPartnerSelectionOption
  ) => {
    form.setValue("customer", {
      id: value.value,
      name: value.name,
      phoneNumber: value.phoneNumber,
      type: value.type,
      customerTypeId: value.customerTypeId,
      customerType: value.customerType,
      hex: value.typeHex,
    });
  };

  const handleDeleteOrderItem = (index: number) => {
    let newOrders = [...form.getValues().orders];

    delete newOrders[index];

    newOrders = newOrders.filter(Boolean);

    form.setValue("orders", newOrders, {
      shouldValidate: true,
    });
  };

  const selectedCustomer = formValues.customer;

  return {
    selectedCustomer,
    handleAddExistingCustomerInfo,
    handleDeleteOrderItem,
    handleNext: form.handleSubmit(
      async () =>
        await handleNext({
          isEditOrder,
          transactionId,
          form,
          handleShowAlert: handleShowRepeatTransactionModal,
          handleGoToPaymentSummary,
        })
    ),
  };
};

export default useCartSectionHandler;
