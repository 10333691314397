"use client";

import { phoneNumberSchema } from "src/validators";
import { z } from "zod";

export type CreateCustomerModalSchemaType = z.infer<
  typeof createCustomerModalSchema
>;

export const createCustomerModalSchema = z.object({
  name: z.string().min(1, { message: "Required" }),
  phoneNumber: phoneNumberSchema(true),
});
