import {
  Chart,
  DiscountShape,
  Monitor,
  People,
  ReceiptItem,
  Setting2,
  ShopAdd,
  TableDocument,
  User,
} from "iconsax-react";
import { RoutePaths } from "src/pages/routePaths";
import { AccessResource } from "src/types/accessControl";
import { NavItemProps } from "./NavItem";

export const SideBarList: NavItemProps[] = [
  {
    title: "Overview",
    accessResource: AccessResource["Overview"],
    icon: <TableDocument />,
    routePath: RoutePaths.Overview,
  },
  {
    title: "POS",
    accessResource: AccessResource["POS"],
    icon: <ShopAdd />,
    routePath: RoutePaths.POS,
  },
  {
    title: "Order Management",
    icon: <ReceiptItem />,
    multiple: true,
    subMenus: [
      {
        title: "Order List",
        accessResource: AccessResource["Order Management - Order List"],
        routePath: RoutePaths.OrderManagementOrderList,
      },
      {
        title: "Customer Transaction List",
        accessResource:
          AccessResource["Order Management - Customer Transaction List"],
        routePath: RoutePaths.OrderManagementCustomerTransactionList,
      },
      {
        title: "Sub Out Transaction List",
        accessResource:
          AccessResource["Order Management - Sub Out Transaction List"],
        routePath: RoutePaths.OrderManagementSubOutTransactionList,
      },
      {
        title: "Return Item List",
        accessResource: AccessResource["Order Management - Return Item List"],
        routePath: RoutePaths.OrderManagementReturnItemList,
      },
    ],
  },
  {
    title: "User Management",
    icon: <People />,
    multiple: true,
    subMenus: [
      {
        title: "Customer List",
        accessResource: AccessResource["User Management - Customer List"],
        routePath: RoutePaths.UserManagementCustomerList,
      },
      {
        title: "Partner / Vendor List",
        accessResource:
          AccessResource["User Management - Partner or Vendor List"],
        routePath: RoutePaths.UserManagementPartnerOrVendorList,
      },
      {
        title: "Supplier List",
        accessResource: AccessResource["User Management - Supplier List"],
        routePath: RoutePaths.UserManagementSupplierList,
      },
    ],
  },
  {
    title: "Product Management",
    icon: <Monitor />,
    multiple: true,
    subMenus: [
      {
        title: "Stock Purchase",
        accessResource: AccessResource["Product Management - Stock Purchase"],
        routePath: RoutePaths.ProductManagementStockPurchase,
      },
      {
        title: "Inventory",
        accessResource: AccessResource["Product Management - Inventory"],
        routePath: RoutePaths.ProductManagementInventory,
      },
      {
        title: "Product List",
        accessResource: AccessResource["Product Management - Product List"],
        routePath: RoutePaths.ProductManagementProductList,
      },
      {
        title: "Product Package",
        accessResource: AccessResource["Product Management - Product Package"],
        routePath: RoutePaths.ProductManagementProductPackage,
      },
      {
        title: "Warehouse",
        accessResource: AccessResource["Product Management - Warehouse"],
        routePath: RoutePaths.ProductManagementWarehouse,
      },
    ],
  },
  {
    title: "Report",
    icon: <Chart />,
    multiple: true,
    subMenus: [
      {
        title: "Overview Report",
        accessResource: AccessResource["Report | Overview Report"],
        routePath: RoutePaths.ReportOverviewReport,
      },
      {
        title: "Sales Report",
        accessResource: AccessResource["Report | Sales Report"],
        routePath: RoutePaths.ReportSalesReport,
      },
      {
        title: "Product Usage Report",
        accessResource: AccessResource["Report | Product Usage Report"],
        routePath: RoutePaths.ReportProductUsageReport,
      },
      {
        title: "Expenses Report",
        accessResource: AccessResource["Report | Expenses & Income Report"],
        routePath: RoutePaths.ReportExpenseAndIncomeReport,
      },
      {
        title: "PnL Report",
        accessResource: AccessResource["Report | PnL Report"],
        routePath: RoutePaths.ReportPnLReport,
      },
    ],
  },
  {
    title: "Promocode",
    accessResource: AccessResource["Promo Code"],
    icon: <DiscountShape />,
    routePath: RoutePaths.PromoCode,
  },
  {
    title: "Admin Management",
    icon: <User />,
    multiple: true,
    subMenus: [
      {
        title: "Admin List",
        accessResource: AccessResource["Admin Management - Admin List"],
        routePath: RoutePaths.AdminManagementAdminList,
      },
      {
        title: "Admin Action Log",
        accessResource: AccessResource["Admin Management - Admin Action Log"],
        routePath: RoutePaths.AdminManagementAdminActionLog,
      },
      {
        title: "Admin Role",
        accessResource: AccessResource["Admin Management - Admin Role"],
        routePath: RoutePaths.AdminManagementAdminRole,
      },
    ],
  },
  {
    title: "Master Data",
    icon: <Setting2 />,
    multiple: true,
    subMenus: [
      {
        title: "Vendor/Partner List",
        accessResource: AccessResource["Master Data - Vendor or Partner List"],
        routePath: RoutePaths.MasterDataVendorOrPartnerList,
      },
      {
        title: "Supplier List",
        accessResource: AccessResource["Master Data - Supplier List"],
        routePath: RoutePaths.MasterDataSupplierList,
      },
      {
        title: "Business Operation",
        accessResource: AccessResource["Master Data - Business Operation"],
        routePath: RoutePaths.MasterDataBusinessOperation,
      },
      {
        title: "Additional Fee",
        accessResource: AccessResource["Master Data - Additional Fee"],
        routePath: RoutePaths.MasterDataAdditionalFee,
      },
      {
        title: "Warehouse Data",
        accessResource: AccessResource["Master Data - Warehouse Data"],
        routePath: RoutePaths.MasterDataWarehouseData,
      },
      {
        title: "Product Category",
        accessResource: AccessResource["Master Data - Product Category"],
        routePath: RoutePaths.MasterDataProductCategory,
      },
      {
        title: "Package Category",
        accessResource: AccessResource["Master Data - Package Category"],
        routePath: RoutePaths.MasterDataPackageCategory,
      },
      {
        title: "Customer Type",
        accessResource: AccessResource["Master Data - Customer Type"],
        routePath: RoutePaths.MasterDataCustomerType,
      },
      {
        title: "Item Stock Unit",
        accessResource: AccessResource["Master Data - Item Stock Unit"],
        routePath: RoutePaths.MasterDataItemStockUnit,
      },
      {
        title: "Expenses and Income Category",
        accessResource:
          AccessResource["Master Data - Expense and Income Category"],
        routePath: RoutePaths.MasterDataExpenseAndIncomeCategory,
      },
    ],
  },
];
