import { Edit, Trash } from "iconsax-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import ReactShowMoreText from "react-show-more-text";
import CustomInput from "src/components/CustomInput";
import { Button } from "src/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { reactShowMoreTextConfig } from "src/config/reactShowMoreText.config";
import { convertToCurrency } from "src/utils/convertToCurrency";
import { getPOSPackagePricing } from "./hooks/useAddProductModalHandler";
import {
  POSCartItemType,
  SelectedPackageProductType,
} from "./hooks/usePOSHandler";
import { PosCartSchemaType } from "./posCartSchema";

interface POSCartCardProps {
  form?: UseFormReturn<PosCartSchemaType, any, undefined>;
  posCartItem: POSCartItemType;
  orderItem: PosCartSchemaType["orders"][number];
  selectedPackageProducts: SelectedPackageProductType[];
  index: number;
  handleDeleteOrderItem?: (index: number) => void;
  handleEditOrderItem?: ({
    orderItem,
    index,
  }: {
    orderItem: PosCartSchemaType["orders"][number];
    index: number;
  }) => void;
}

const POSCartCard: React.FC<POSCartCardProps> = ({
  form,
  posCartItem,
  orderItem,
  selectedPackageProducts,
  index,
  handleDeleteOrderItem,
  handleEditOrderItem,
}) => {
  const itemType = posCartItem?.type;
  const businessOperationName = orderItem.businessOperationName;
  const subCategoryColor = posCartItem.sub_category.hex;
  const imageUrl = posCartItem.image_url;
  const itemName = posCartItem?.product?.name || posCartItem?.package?.name;
  const itemVariantName = posCartItem.name;
  const warehouseName =
    posCartItem?.stocks?.find(
      (stock) => `${stock.warehouse.id}` === `${orderItem?.warehouse?.id}`
    )?.warehouse?.name ?? "";
  const availableStock = posCartItem.available;
  const note = orderItem.note;

  const name = `${itemVariantName} - ${itemName}`;
  const isRent =
    orderItem.markAsRent || (!posCartItem.is_purchase && posCartItem.is_rent);
  const itemQuantityAndPrice = `${parseInt(
    `${orderItem.warehouse.quantity}`,
    0
  )} ${posCartItem.item_stock_unit.name} x ${convertToCurrency(
    parseInt(`${orderItem.price}`)
  )}`;

  const totalPrice = Math.max(
    parseInt(orderItem.price, 0) *
      parseInt(`${orderItem.warehouse.quantity}`, 0) -
      parseInt(`${orderItem.discount}`, 0),
    0
  );

  const { packageRemainingPrice } = getPOSPackagePricing({
    products: orderItem.products,
    price: orderItem.price,
    discount: orderItem.discount,
    quantity: parseInt(orderItem.quantity),
  });
  const packageProducts = orderItem.products;

  return (
    <div
      className="relative p-3 pt-2 pl-5 rounded-lg overflow-hidden w-full"
      style={{ borderWidth: 1.5 }}
    >
      <div className="grid gap-3">
        <span
          className="font-medium text-darkBrown-default"
          style={{ fontSize: 10 }}
        >
          {businessOperationName}
        </span>

        <div className="flex w-full">
          <div className="w-full">
            <div className="flex gap-3">
              <div className="flex flex-col">
                <img
                  src={imageUrl}
                  alt="preview"
                  className="w-14 h-14 object-cover rounded-lg"
                  style={{ borderBottomWidth: 1.5 }}
                />

                {handleEditOrderItem && handleDeleteOrderItem && (
                  <div className="flex justify-between item gap-1">
                    <Button
                      className="p-0.5"
                      variant="link"
                      type="button"
                      onClick={() =>
                        handleEditOrderItem({
                          orderItem,
                          index,
                        })
                      }
                    >
                      <Edit size={21} className="text-blueIndicator-30" />
                    </Button>

                    <Button
                      className="p-0.5"
                      variant="link"
                      type="button"
                      onClick={() => handleDeleteOrderItem(index)}
                    >
                      <Trash size={21} className="text-redIndicator-40" />
                    </Button>
                  </div>
                )}
              </div>

              <div className="text-xs font-semibold text-primary-100 leading-4 flex-1">
                <ReactShowMoreText
                  {...reactShowMoreTextConfig}
                  lines={2}
                  width={undefined}
                  more={""}
                >
                  {name}
                </ReactShowMoreText>

                <div className="flex flex-col mt-1">
                  {itemType === "product" && (
                    <span
                      className="font-normal"
                      style={{ fontSize: 10, marginBottom: -2 }}
                    >{`${isRent ? "Rent" : "Purchase"} Item`}</span>
                  )}

                  <span className="font-semibold" style={{ fontSize: 10 }}>
                    {itemQuantityAndPrice}
                  </span>
                </div>

                {itemType === "product" && (
                  <div className="flex flex-col">
                    <span
                      className="font-normal"
                      style={{ fontSize: 10, marginBottom: -2 }}
                    >
                      Warehouse
                    </span>

                    <span className="font-semibold" style={{ fontSize: 10 }}>
                      {warehouseName}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-1/3 flex flex-col justify-between items-end">
            <span className="text-xs text-end font-semibold text-primary-100 leading-4">
              {convertToCurrency(totalPrice)}
            </span>

            {form && (
              <div className="-mr-2">
                <CustomInput
                  form={form}
                  name={
                    itemType === "product"
                      ? `orders.${index}.warehouse.quantity`
                      : `orders.${index}.quantity`
                  }
                  variant="quantity-changer"
                  minValue={1}
                  maxValue={availableStock}
                  quantityChangerSize="small"
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {itemType === "package" && (
            <>
              <div className="flex gap-2 -mt-3">
                <span className="text-xs font-medium text-darkBrown-default">
                  Remaining Price
                </span>

                <span className="text-xs font-normal text-darkBrown-default">
                  {convertToCurrency(packageRemainingPrice)}
                </span>
              </div>

              <div className="flex -mb-1">
                <Table className="mb-3 -mx-2 border-0" noShadow>
                  <TableHeader className="p-0" noBorderBottom>
                    <TableRow
                      className="bg-white border-0 h-auto"
                      style={{ fontSize: 12 }}
                    >
                      <TableHead className="font-normal text-darkIndicator-40 px-2 py-0 whitespace-nowrap h-auto pb-1">
                        Item Name
                      </TableHead>
                      <TableHead className="font-normal text-darkIndicator-40 px-2 py-0 whitespace-nowrap h-auto pb-1">
                        Qty
                      </TableHead>
                      <TableHead className="font-normal text-darkIndicator-40 px-2 py-0 whitespace-nowrap h-auto pb-1">
                        Warehouse
                      </TableHead>
                      <TableHead className="font-normal text-darkIndicator-40 px-2 py-0 whitespace-nowrap h-auto pb-1">
                        Total Price
                      </TableHead>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {packageProducts?.map((packageProduct, index) => {
                      const selectedPackageProduct =
                        selectedPackageProducts.find(
                          (selectedPackageProduct) =>
                            `${selectedPackageProduct.id}` ===
                              `${packageProduct.id}` &&
                            `${selectedPackageProduct.packageVariantId}` ===
                              `${orderItem.id}`
                        );

                      const itemName = `${selectedPackageProduct?.productName} - ${selectedPackageProduct?.variantName}`;
                      const itemType = `${
                        packageProduct.markAsRent ? "Rent" : "Purchase"
                      } Item`;
                      const itemQuantity = `${packageProduct.quantity} ${selectedPackageProduct?.itemStockUnit}`;
                      const itemWarehouse = packageProduct.warehouseName;
                      const totalItemPrice = Math.max(
                        Math.max(
                          parseInt(`${packageProduct.price}`, 0) *
                            parseInt(`${packageProduct.quantity}`, 0),
                          0
                        ) - parseInt(`${packageProduct.discount}`, 0),
                        0
                      );

                      return (
                        <TableRow className="text-xs border-b-0" key={index}>
                          <TableCell
                            className={`px-2 py-0 h-auto ${
                              index !== 0 ? "pt-2" : ""
                            }`}
                          >
                            <div className="flex flex-col items-start">
                              <span>{itemName}</span>

                              <div className="-mt-0.5">
                                <span
                                  className="font-normal text-darkIndicator-40"
                                  style={{ fontSize: 10 }}
                                >
                                  {itemType}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={`px-2 py-0 h-auto ${
                              index !== 0 ? "pt-2" : ""
                            }`}
                          >
                            {itemQuantity}
                          </TableCell>
                          <TableCell
                            className={`px-2 py-0 h-auto ${
                              index !== 0 ? "pt-2" : ""
                            }`}
                          >
                            {itemWarehouse}
                          </TableCell>
                          <TableCell
                            className={`px-2 py-0 h-auto whitespace-nowrap ${
                              index !== 0 ? "pt-2" : ""
                            }`}
                          >
                            {convertToCurrency(totalItemPrice)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}

          {form ? (
            <CustomInput
              form={form}
              name={`orders.${index}.note`}
              placeholder="Add Product Notes"
              textArea
              maxCharacters={500}
            />
          ) : (
            note && (
              <div className="w-full flex flex-col border rounded-md p-2 gap-1">
                <span className="font-normal" style={{ fontSize: 10 }}>
                  Order Notes
                </span>
                <span
                  className="font-medium break-all"
                  style={{ fontSize: 10 }}
                >
                  {note}
                </span>
              </div>
            )
          )}
        </div>
      </div>

      <div
        className="w-2.5 h-full absolute left-0 bottom-0"
        style={{ backgroundColor: subCategoryColor }}
      />
    </div>
  );
};

export default POSCartCard;
