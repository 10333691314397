import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Form } from "../ui/form";
import {
  createCustomerModalSchema,
  CreateCustomerModalSchemaType,
} from "./createCustomerModalSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerAndPartnerSelectionOption } from "./hooks/useCustomerAndPartnerSelectionHandler";
import { defaultCountryCode, inputPlaceHolder } from "src/constant";
import { useEffect } from "react";

interface CreateCustomerModalProps {
  searchInput: string;
  handleChangeSearchInput: (search: string) => void;
  isShow: boolean;
  onClose: () => void;
  onValueChange: (value: CustomerAndPartnerSelectionOption) => void;
}

const CreateCustomerModal = ({
  searchInput,
  handleChangeSearchInput,
  isShow,
  onClose,
  onValueChange,
}: CreateCustomerModalProps) => {
  const form = useForm<CreateCustomerModalSchemaType>({
    resolver: zodResolver(createCustomerModalSchema),
    defaultValues: {
      name: searchInput ?? "",
      phoneNumber: defaultCountryCode,
    },
  });

  const formValues = form.getValues();

  const handleAddCustomer = form.handleSubmit(() => {
    onValueChange({
      label: "",
      value: "",
      name: formValues.name,
      phoneNumber: formValues.phoneNumber,
      type: "customer",
      customerTypeId: "",
      customerType: "",
      typeHex: "",
    });

    handleCloseModal();
  });

  const handleCloseModal = () => {
    onClose();
    form.reset({ name: "", phoneNumber: defaultCountryCode });
  };

  useEffect(() => {
    if (isShow) {
      form.reset({ name: searchInput, phoneNumber: defaultCountryCode });
      handleChangeSearchInput("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, form]);

  return (
    <Dialog open={isShow} onOpenChange={handleCloseModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-md font-semibold text-darkBrown-default">
            Create Customer
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col items-center gap-4 pt-2 pb-5">
          <Form {...form}>
            <CustomInput
              form={form}
              name="name"
              labelText="Customer Name"
              placeholder="Input name"
            />

            <CustomInput
              form={form}
              name="phoneNumber"
              labelText="Customer Phone Number"
              placeholder={inputPlaceHolder["phone_number"]}
              variant="phone-number"
            />
          </Form>
        </div>

        <DialogFooter>
          <Button
            type="submit"
            onClick={handleAddCustomer}
            isLoading={form.formState.isSubmitting}
          >
            Add Customer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCustomerModal;
