import { ChevronDown, ChevronUp } from "lucide-react";
import React from "react";
import useNavItem from "./hooks/useNavItem";
import { AccessResource } from "src/types/accessControl";

export interface NavItemProps {
  title: string;
  accessResource?: AccessResource;
  icon: any;
  multiple?: boolean;
  routePath?: string;
  subMenus?: NavItemSubMenuProps[];
}

export interface NavItemSubMenuProps {
  title: string;
  accessResource: AccessResource;
  routePath: string;
}

const NavItem: React.FC<NavItemProps> = ({
  title,
  icon,
  multiple = false,
  routePath = "",
  accessResource,
  subMenus = [],
}) => {
  const {
    isActive,
    isShowDetail,
    activeSubMenu,
    handleMenuClick,
    handleShowDetail,
    handleSubMenuClick,
  } = useNavItem({ multiple, routePath, subMenus });

  return (
    <a
      {...(!multiple && { href: routePath })}
      className={`${
        isActive ? "text-primary-100 font-medium" : "text-white font-light"
      } w-full text-lg select-none outline-none`}
    >
      <div
        onClick={() => {
          if (!multiple) {
            handleMenuClick(routePath);
          } else {
            handleShowDetail();
          }
        }}
        className={`hover:text-primary-100 flex items-center gap-2 pl-5 pr-2 py-1 mr-5 transition-all cursor-pointer relative rounded-b-sm rounded-t-sm ${
          isActive ? "bg-white" : "hover:bg-white"
        }`}
      >
        {icon}

        <span className="w-full hover:text-primary-100">{title}</span>
        {multiple && (isShowDetail ? <ChevronUp /> : <ChevronDown />)}
      </div>

      {isShowDetail && (
        <div className="flex flex-col">
          {subMenus.map((subMenu, index) => (
            <div
              className={`flex items-center gap-3 pl-10 pr-2 py-1 mr-5 transition-all cursor-pointer relative rounded-b-sm rounded-t-sm ${
                activeSubMenu === subMenu.routePath
                  ? "text-primary-100 bg-primary-40 font-normal"
                  : "text-white hover:bg-primary-40 font-light"
              } hover:text-primary-100`}
              onClick={(e) => {
                e.stopPropagation();
                handleSubMenuClick(subMenu.routePath);
              }}
              key={index}
            >
              {subMenu.title}
            </div>
          ))}
        </div>
      )}
    </a>
  );
};

export default NavItem;
