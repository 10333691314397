import axios from "axios";
import { RoutePaths } from "src/pages/routePaths";
import { getAuthTokens, tokenNames } from "src/utils/getAuthTokens";
import { refreshToken } from "./Auth.service";
import { ErrorType } from "src/types/apiResponse";

const AF_API_ROUTE_ENV = {
  staging: "https://api-staging.angiefior.com",
  production: "https://api.angiefior.com",
} as const;

const SM_API_ROUTE_ENV = {
  staging: "https://api-staging.solusimekanik.com",
  production: "https://api.solusimekanik.com",
} as const;

type AFApiEnvironment = keyof typeof AF_API_ROUTE_ENV;
type SMApiEnvironment = keyof typeof SM_API_ROUTE_ENV;

const appName = process.env.REACT_APP_APP_NAME;
const nodeENV = process.env.REACT_APP_NODE_ENV;

let baseURL: string = AF_API_ROUTE_ENV[nodeENV as AFApiEnvironment];

switch (appName) {
  case "angie-fior":
    baseURL = AF_API_ROUTE_ENV[nodeENV as AFApiEnvironment];
    break;
  case "solusi-mekanik":
    baseURL = SM_API_ROUTE_ENV[nodeENV as SMApiEnvironment];
    break;

  default:
    break;
}

const API = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

API.interceptors.request.use(
  async (req) => {
    const { token, refreshToken } = getAuthTokens();

    if (token) {
      req.headers.access_token = token;
      req.headers.refresh_token = refreshToken;
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    // if (error?.message === "Network Error") {
    //   window.location.replace(RoutePaths.SignIn);
    // }

    const { status } = error.response || {};

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        const originalRequest = error.config;

        try {
          await refreshToken();

          isRefreshing = false;
          return API(originalRequest);
        } catch (refreshError) {
          const refreshTokenErrorMessage = (refreshError as ErrorType)?.response
            ?.data?.message;

          isRefreshing = false;

          if (refreshTokenErrorMessage === "Invalid token") {
            localStorage.removeItem(tokenNames["token"]);
            localStorage.removeItem(tokenNames["refreshToken"]);
            window.location.replace(RoutePaths.SignIn);
          }

          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default API;
