"use client";

import { phoneNumberSchema } from "src/validators";
import { z } from "zod";
import { addPOSProductSchema } from "./addProductModalSchema";

export type PosCartSchemaType = z.infer<typeof posCartSchema>;

export const posCartSchemaObject = {
  id: z.number().optional(),
  customer: z.object({
    id: z.string().nullable(),
    name: z.string().min(1, { message: "Required" }),
    phoneNumber: phoneNumberSchema(false),
    type: z.union([z.literal("customer"), z.literal("partner")]),
    customerTypeId: z.string().nullable(),
    customerType: z.string().nullable(),
    hex: z.string().nullable(),
  }),
  orders: z.array(
    z.object({
      id: z.number({
        coerce: true,
        invalid_type_error: "Required",
      }),
      type: z.union([z.literal("product"), z.literal("package")]),
      note: z.string(),
      ...addPOSProductSchema,
      warehouses: z.undefined(),
      warehouse: z.object({
        id: z.number({
          coerce: true,
          invalid_type_error: "Required",
        }),
        quantity: z.string().regex(/^\d+$/),
      }),
    })
  ),
  note: z.string(),
  hasRent: z.boolean(),
};

export const posCartSchema = z.object(posCartSchemaObject);
