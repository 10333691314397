import { Notification } from "iconsax-react";
import React from "react";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import NotificationDropdownMenuContent from "./NotificationDropdownMenuContent";

interface NotificationDropdownMenuProps {
  isOpen: boolean;
  handleToggle: () => void;
}

const NotificationDropdownMenu: React.FC<NotificationDropdownMenuProps> = ({
  isOpen,
  handleToggle,
}) => {
  return (
    <DropdownMenu open={isOpen} onOpenChange={handleToggle}>
      <DropdownMenuTrigger asChild>
        <Button className="px-0" variant="ghost">
          <Notification size="32" color={"#433C34"} variant="Bold" />
        </Button>
      </DropdownMenuTrigger>

      {isOpen && <NotificationDropdownMenuContent />}
    </DropdownMenu>
  );
};

export default NotificationDropdownMenu;
