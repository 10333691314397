import NavItem from "./NavItem";

import useSideBar from "./hooks/useSideBar";

interface SideBarProps {
  isOpen: boolean;
}

const SideBar = ({ isOpen }: SideBarProps) => {
  const { menus, appLogo1, appLogo2 } = useSideBar();

  return (
    <div
      className={`h-screen pb-16 overflow-y-auto no-scrollbar sticky top-0 bg-primary transform transition-transform duration-300 w-[220px] lg:w-[280px] ease-in-out border-r !sm-absolute z-50 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="bg-background-100 flex flex-col h-35 items-center justify-center gap-2 px-4 lg:h-[142px] lg:px-12 bg-background-300">
        <img
          className={appLogo2 ? "h-14" : "h-24"}
          src={appLogo1}
          alt="logo1"
        />
        {appLogo2 && <img className="h-14" src={appLogo2} alt="logo2" />}
      </div>

      <div className="flex-1 overflow-y-auto py-4">
        <nav
          className={`grid items-start font-medium ${
            isOpen ? "opacity-100" : "opacity-0"
          } transition-opacity duration-300 ease-in-out gap-3`}
        >
          {menus.map((menu, index) => (
            <NavItem {...menu} key={index} />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
