import { format, isToday, setHours, setMinutes } from "date-fns";
import { Calendar2 } from "iconsax-react";
import React, { useState } from "react";
import { DateRange } from "react-day-picker";
import { cn } from "src/lib/utils";
import { Calendar, CalendarPickerMode, CalendarProps } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

import TimePicker from "./TimePicker";

interface DateRangePickerProps {
  date?: Date | DateRange | undefined;
  setDate?:
    | React.Dispatch<React.SetStateAction<Date | undefined>>
    | React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  placeholder: string;
  type: "SINGLE" | "RANGE";
  className?: string;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  isIncludeTime?: boolean;
  pickerMode?: CalendarPickerMode;
  autoPreFill?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  date,
  setDate,
  placeholder,
  type,
  className,
  disabled = false,
  minDate,
  maxDate,
  isIncludeTime,
  pickerMode,
  autoPreFill,
}) => {
  const selectedDate = date as Date;
  const selectedDateRange = date as DateRange;

  const setDatePicker = setDate as React.Dispatch<
    React.SetStateAction<Date | undefined>
  >;
  const setDateRangePicker = setDate as React.Dispatch<
    React.SetStateAction<DateRange | undefined>
  >;

  const [timeValue, setTimeValue] = useState<string>(
    isToday(selectedDate ?? new Date()) ? format(new Date(), "HH:mm") : "00:00"
  );

  const isMonthMode = pickerMode === "months";
  const isYearMode = pickerMode === "years";

  const labelDateFormat = isMonthMode
    ? "MMMM yyyy"
    : isYearMode
    ? "yyyy"
    : "dd MMMM yyyy";

  const calendarType: CalendarProps =
    type === "SINGLE"
      ? {
          mode: "single",
          selected: selectedDate,
          onSelect: (date) => {
            if (date) {
              if (isIncludeTime) {
                const [hours, minutes] = timeValue
                  .split(":")
                  .map((str) => parseInt(str, 10));
                const newSelectedDate = setHours(
                  setMinutes(date as Date, minutes),
                  hours
                );

                setDatePicker(newSelectedDate);
              } else {
                setDatePicker(date);
              }
            } else {
              setDatePicker(date);
            }
          },
        }
      : {
          mode: "range",
          onSelect: setDateRangePicker,
          defaultMonth: selectedDateRange?.from,
          selected: selectedDateRange,
          numberOfMonths: 2,
        };

  return (
    <Popover modal>
      <PopoverTrigger
        disabled={disabled}
        className={cn(
          "flex flex-1 min-w-44 items-center justify-between font-normal px-2 text-sm bg-white",
          !selectedDateRange && "text-muted-foreground",
          className && className
        )}
      >
        <div className="flex-1 w-full text-left">
          {(type === "SINGLE" ? date : selectedDateRange?.from) ? (
            <>
              {type === "SINGLE" ? (
                <>
                  {!isIncludeTime
                    ? format(selectedDate, labelDateFormat)
                    : format(selectedDate, "dd MMMM yyyy HH:mm")}
                </>
              ) : (
                <>
                  {selectedDateRange.to ? (
                    <span className="text-darkBrown-default">
                      {`${format(
                        selectedDateRange.from as Date,
                        labelDateFormat
                      )} - ${format(selectedDateRange.to, labelDateFormat)}`}
                    </span>
                  ) : (
                    format(selectedDateRange.from as Date, labelDateFormat)
                  )}
                </>
              )}
            </>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>

        <Calendar2 className="h-4 w-4" />
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          autoPreFill={autoPreFill}
          minDate={minDate}
          maxDate={maxDate}
          pickerMode={pickerMode}
          {...calendarType}
          footer={
            isIncludeTime ? (
              <TimePicker
                timeValue={timeValue}
                setTimeValue={setTimeValue}
                selectedDate={selectedDate}
                setDatePicker={setDatePicker}
              />
            ) : undefined
          }
        />
      </PopoverContent>
    </Popover>
  );
};

export default DateRangePicker;
