import { format } from "date-fns";
import {
  AddToCartFormType,
  AddToCartResponse,
  CheckoutFormType,
  CheckoutResponse,
  GoToPaymentFormType,
  GoToPaymentResponse,
  PaymentMethodsResponse,
  POSBuyerDetailAlertParams,
  POSBuyerDetailAlertResponse,
  POSBuyerDetailApplyPromoCodesRequestBody,
  POSBuyerDetailApplyPromoCodesResponse,
  POSBuyerDetailPromoCodeParams,
  POSBuyerDetailPromoCodesResponse,
  POSBuyersParams,
  POSBuyersResponse,
  POSCheckCartRequestBody,
  POSCheckCartResponse,
  POSItemDetail,
  POSItemDetailParams,
  POSItemsParams,
  POSItemsResponse,
  UploadTransferProofFormType,
  UploadTransferProofResponse,
} from "src/pages/POS/api/interfaces";
import {
  getPOSSortingParams,
  POSSortValueType,
} from "src/pages/POS/hooks/usePOSHandler";
import { TransactionSummarySchemaType } from "src/pages/POS/TransactionSummary/transactionSummarySchema";
import { formatInputPhoneNumberValue } from "src/utils/formatPhoneNumber";
import { getPagination } from "src/utils/getPagination";
import API from ".";
import { PaginationType } from "src/components/TablePaginationLimitChanger/hooks/useTablePaginationLimitChanger";

const featureRouteApi = "/pos";

export const convertFormData = (form: TransactionSummarySchemaType) => {
  return {
    payment_method_id: form.paymentMethodId,
    payment_channel_id: form.paymentChannelId,
    ...(form?.customer?.type === "customer"
      ? form?.customer?.id !== ""
        ? {
            customer_id: form?.customer?.id,
            partner_id: null,
          }
        : {
            customer_id: null,
            partner_id: null,
            new_customer: {
              name: form.customer.name,
              phone_number: formatInputPhoneNumberValue(
                form.customer.phoneNumber
              ),
            },
          }
      : {
          customer_id: null,
          partner_id: form?.customer?.id,
        }),
    estimation_start_date: form.estimationDate?.from
      ? format(form.estimationDate?.from, "yyyy-MM-dd")
      : null,
    estimation_end_date: form.estimationDate?.to
      ? format(form.estimationDate?.to, "yyyy-MM-dd")
      : null,
    is_save: form.recipient?.isSave ?? false,
    recipient: form.recipient
      ? {
          recipient_id: form.recipient?.isSave ? null : form.recipient?.id,
          name: form.recipient.name,
          phone_number: formatInputPhoneNumberValue(form.recipient.phoneNumber),
          country: form.recipient.country,
          city: form.recipient.city,
          address: form.recipient.address,
          delivery_date: form?.recipient?.deliveryDate
            ? format(form.recipient.deliveryDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          return_date: form.recipient.returnDate
            ? format(form.recipient.returnDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          gift_card_notes: form.recipient.note,
        }
      : null,
    deposit: parseInt(form.deposit, 0) === 0 ? null : parseInt(form.deposit),
    total_paid: form.totalPaid ?? null,
    notes: form.note,
    order_method: form.orderMethod,
    pickup_notes: form.pickUpNote,
    order_date: form.orderDate
      ? `${format(form.orderDate, "yyyy-MM-dd")} ${format(
          new Date(),
          "HH:mm:ss"
        )}`
      : null,
    return_date: form.returnDate
      ? format(form.returnDate, "yyyy-MM-dd HH:mm:ss")
      : null,
    pickup_date: form.pickUpDate
      ? format(form.pickUpDate, "yyyy-MM-dd HH:mm:ss")
      : null,
    products: form.orders
      .filter((order) => order.type === "product")
      .map((orderProduct) => ({
        business_operation_id: orderProduct.businessOperationId,
        product_variant_id: orderProduct.id,
        marked_rent: orderProduct.markAsRent,
        adjusted_price: orderProduct.price ?? null,
        discount: orderProduct.discount ?? null,
        warehouse_id: orderProduct.warehouse.id,
        quantity: orderProduct.warehouse.quantity,
        notes: orderProduct.note,
      })),
    packages: form.orders
      .filter((order) => order.type === "package")
      .map((orderPackage) => ({
        business_operation_id: orderPackage.businessOperationId,
        edited_price: orderPackage.price ?? null,
        package_variant_id: orderPackage.id,
        quantity: orderPackage.quantity,
        remaining_discount: orderPackage.discount ?? null,
        notes: orderPackage.note,
        variant_products: orderPackage.products.map((product) => ({
          product_variant_id: product.id,
          warehouse_id: product.warehouseId,
          marked_rent: product.markAsRent,
          quantity: product.quantity,
          adjusted_price: product.price ?? null,
          discount: product.discount ?? null,
        })),
      })),
    additional_fees: form.additionalFees.map((additionalFee) => ({
      business_operation_id: additionalFee.businessOperationId,
      additional_fee_id: additionalFee.id,
      fee: additionalFee.price,
    })),
    promocodes: form.promoCodes,
  };
};

export const getPOSItems = async ({
  pageParam,
  offset,
  productName,
  variantName,
  subCategoryId,
  subCategoryType,
  sortBy,
}: POSItemsParams & {
  pageParam: PaginationType;
}): Promise<POSItemsResponse> => {
  const paginationParam = getPagination({
    limit: 10,
    offset: pageParam.offset > 0 ? pageParam.offset : offset,
  });

  const response = await API.get<POSItemsResponse>(`${featureRouteApi}/items`, {
    params: {
      ...paginationParam,
      ...(productName ? { product_name: productName } : {}),
      ...(variantName ? { variant_name: variantName } : {}),
      ...(subCategoryId !== "*" ? { sub_category_id: subCategoryId } : {}),
      ...(subCategoryType !== "all" ? { type: subCategoryType } : {}),
      ...(sortBy ? getPOSSortingParams(sortBy as POSSortValueType) : {}),
    },
  });

  return {
    ...response.data,
    prevOffset:
      (response.data.meta.current_page - 1) * response.data.meta.per_page,
  } as any;
};

export const getPOSItemDetail = async ({
  id,
  type,
  edit,
  transactionId,
}: POSItemDetailParams): Promise<POSItemDetail> => {
  const response = await API.get<POSItemDetail>(
    `${featureRouteApi}/items/${id}`,
    {
      params: {
        type,
        edit,
        transaction_id: transactionId,
      },
    }
  );

  return response.data;
};

export const getPOSBuyers = async ({
  limit,
  offset,
  qn,
}: POSBuyersParams): Promise<POSBuyersResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<POSBuyersResponse>(
    `${featureRouteApi}/buyers`,
    {
      params: {
        ...paginationParam,
        ...(qn ? { qn } : {}),
      },
    }
  );

  return response.data;
};

export const getPOSBuyerPromoCodes = async ({
  limit,
  offset,
  selectedPromoCodeIds,
  id,
  type,
  orderDetails,
}: POSBuyerDetailPromoCodeParams): Promise<POSBuyerDetailPromoCodesResponse | null> => {
  if (!id || !orderDetails) return null;

  const paginationParam = getPagination({ limit, offset });

  const requestBody = {
    ...paginationParam,
    selected_promocode_ids: selectedPromoCodeIds,
    type,
    ...orderDetails,
  };

  const response = await API.put<POSBuyerDetailPromoCodesResponse>(
    `${featureRouteApi}/buyers/${id}/promocodes`,
    requestBody
  );

  return response.data;
};

export const applyPOSBuyerPromoCodes = async ({
  id,
  type,
  form,
}: POSBuyerDetailApplyPromoCodesRequestBody): Promise<POSBuyerDetailApplyPromoCodesResponse> => {
  const convertedData = convertFormData(form);

  const requestBody = {
    type,
    products: convertedData.products,
    packages: convertedData.packages,
    additional_fees: convertedData.additional_fees,
    promocodes: convertedData.promocodes,
  };

  const response = await API.post<POSBuyerDetailApplyPromoCodesResponse>(
    `${featureRouteApi}/buyers/${id}/promocodes`,
    requestBody
  );

  return response.data;
};

export const getPOSBuyerDetailAlert = async ({
  id,
  type,
  edit,
  transactionId,
}: POSBuyerDetailAlertParams): Promise<POSBuyerDetailAlertResponse> => {
  const response = await API.get<POSBuyerDetailAlertResponse>(
    `${featureRouteApi}/buyers/${id}`,
    {
      params: {
        type,
        ...(edit
          ? {
              edit,
              transaction_id: transactionId,
            }
          : {}),
      },
    }
  );

  return response.data;
};

export const checkPOSCart = async ({
  form,
  edit,
  transactionId,
}: POSCheckCartRequestBody): Promise<POSCheckCartResponse> => {
  const requestBody = {
    products: form.orders
      .filter((order) => order.type === "product")
      .map((order) => ({
        business_operation_id: order.businessOperationId,
        product_variant_id: order.id,
        warehouse_id: order.warehouse.id,
        quantity: order.warehouse.quantity,
        marked_rent: order.markAsRent,
      })),
    packages: form.orders
      .filter((order) => order.type === "package")
      .map((order) => ({
        business_operation_id: order.businessOperationId,
        package_variant_id: order.id,
        quantity: order.quantity,
        variant_products: order.products.map((product) => ({
          product_variant_id: product.id,
          warehouse_id: product.warehouseId,
          quantity: product.quantity,
          marked_rent: product.markAsRent,
        })),
      })),
    ...(edit
      ? {
          edit,
          transaction_id: transactionId,
        }
      : {}),
  };

  const response = await API.post<POSCheckCartResponse>(
    `${featureRouteApi}/carts`,
    requestBody
  );

  return response.data;
};

export const getPaymentMethods = async (): Promise<PaymentMethodsResponse> => {
  const response = await API.get<PaymentMethodsResponse>("/payment-methods");

  return response.data;
};

export const addToCart = async ({
  posFormValues,
  formValues,
  id,
  type,
  edit,
  transactionId,
}: AddToCartFormType): Promise<AddToCartResponse> => {
  let requestBody: any = {
    existing: {
      products: posFormValues.orders
        .filter((order) => order.type === "product")
        .map((order) => ({
          business_operation_id: order.businessOperationId,
          product_variant_id: order.id,
          warehouse_id: order.warehouse.id,
          quantity: order.quantity,
          marked_rent: order.markAsRent,
        })),
      packages: posFormValues.orders
        .filter((order) => order.type === "package")
        .map((order) => ({
          business_operation_id: order.businessOperationId,
          package_variant_id: order.id,
          quantity: order.quantity,
          variant_products: order.products.map((product) => ({
            product_variant_id: product.id,
            warehouse_id: product.warehouseId,
            quantity: product.quantity,
            marked_rent: product.markAsRent,
          })),
        })),
    },
    edit,
    transaction_id: transactionId,
  };

  if (type === "product") {
    requestBody = {
      ...requestBody,
      new: {
        product: {
          business_operation_id: formValues.businessOperationId,
          product_variant_id: id,
          stocks: formValues.warehouses.map((warehouse) => ({
            warehouse_id: warehouse.id,
            quantity: warehouse.quantity,
          })),
          marked_rent: formValues.markAsRent,
        },
      },
    };
  } else if (type === "package") {
    requestBody = {
      ...requestBody,
      new: {
        package: {
          business_operation_id: formValues.businessOperationId,
          package_variant_id: id,
          quantity: formValues.quantity,
          variant_products: formValues.products.map((product) => ({
            product_variant_id: product.id,
            warehouse_id: product.warehouseId,
            quantity: product.quantity,
            marked_rent: product.markAsRent,
          })),
        },
      },
    };
  }

  const response = await API.put<AddToCartResponse>(
    `${featureRouteApi}/items/stocks`,
    requestBody
  );

  return response.data;
};

export const goToPayment = async ({
  form,
  edit,
  transactionId,
}: GoToPaymentFormType): Promise<GoToPaymentResponse> => {
  const requestBody = convertFormData(form);

  const response = await API.post<GoToPaymentResponse>(
    `${featureRouteApi}/carts`,
    {
      ...requestBody,
      ...(edit
        ? {
            edit,
            transaction_id: transactionId,
          }
        : {}),
    }
  );

  return response.data;
};

export const checkout = async ({
  form,
}: CheckoutFormType): Promise<CheckoutResponse> => {
  const requestBody = convertFormData(form);

  const response = await API.post<CheckoutResponse>(
    `${featureRouteApi}/checkout`,
    requestBody
  );

  return response.data;
};

export const uploadTransferProof = async ({
  id,
  uploadProof,
  adminNotes,
}: UploadTransferProofFormType): Promise<UploadTransferProofResponse> => {
  const requestBody = {
    upload_proof: uploadProof !== "" ? uploadProof : null,
    admin_notes: adminNotes,
  };
  const response = await API.put<UploadTransferProofResponse>(
    `${featureRouteApi}/transactions/${id}/proof`,
    requestBody
  );

  return response.data;
};

export const editOrder = async ({
  form,
}: CheckoutFormType): Promise<CheckoutResponse> => {
  const requestBody = convertFormData(form);

  const response = await API.put<CheckoutResponse>(
    `/transactions/${form.id}`,
    requestBody
  );

  return response.data;
};
