const locale = "id-ID";
const currency = "IDR";

export const convertToCurrency = (amount: number = 0): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const convertCurrencyToString = (formattedString: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(1234.5);
  const currencySymbol = parts?.find((part) => part.type === "currency")?.value;
  const groupingSeparator = parts?.find((part) => part.type === "group")?.value;

  let cleanedString = formattedString
    .replace(new RegExp(`\\${currencySymbol}`, "g"), "") // Remove currency symbol
    .replace(new RegExp(`\\${groupingSeparator}`, "g"), ""); // Remove grouping separators

  return cleanedString;
};

export const displayCurrencyInShort = (amount: number): string => {
  if (amount >= 1e12) {
    return `Rp ${(amount / 1e12).toFixed(1)}T`; // Trillion
  } else if (amount >= 1e9) {
    return `Rp ${(amount / 1e9).toFixed(1)}B`; // Billion (if needed)
  } else if (amount >= 1e6) {
    return `Rp ${(amount / 1e6).toFixed(1)}M`; // Million
  } else {
    return convertToCurrency(amount); // If less than a million
  }
};
